<template>
  <div>
    <b-modal 
      id="userAgreementModal" 
      scrollable 
      size="lg"
      title="Пользовательское соглашение"
      v-model="showModal"
      @show="onShow"
      @hidden="onHide">
      <h6>1. ОБЩИЕ ПОЛОЖЕНИЯ</h6>
      <p class="fs--1">
        <strong>1.1.</strong>	Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту Сервиса <strong>ТВОЙ</strong><small class="text-400 text-uppercase"> бизнес-план</small>, расположенному по адресу https://www.your-plan.ru 
        <br>
        <strong>1.2.</strong> 	Сервис <strong>ТВОЙ</strong><small class="text-400 text-uppercase"> бизнес-план</small> (далее – Сервис) является собственностью ООО «Р-СИСТЕМС» (ИНН: 9729136528), юр.адрес: 119602, ГОРОД МОСКВА, УЛИЦА ПОКРЫШКИНА, ДОМ 11, ПОМ XII, оф.4
        <br>
        <strong>1.3.</strong>	Настоящее Соглашение регулирует отношения между Администрацией сайта Сервиса (далее – Администрация сервиса) и Пользователем данного Сервиса (далее – Пользователь).
        <br>
        <strong>1.4.</strong> Администрация сервиса оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.
        <br>
        <strong>1.5.</strong> Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.
        <br>
        <strong>1.6.</strong> Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.
      </p>
      <h6>2. ТЕРМИНЫ и ОПРЕДЕЛЕНИЯ</h6>
      <p class="fs--1">
        <strong>2.1.</strong> Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
        <br>
        <strong>2.1.1</strong> «<strong>ТВОЙ</strong><small class="text-400 text-uppercase"> бизнес-план</small>» – Интернет-сервис, расположенный на доменном имени https://www.your-plan.ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.
        <br>
        <strong>2.1.2.</strong> Интернет-сервис – сайт, представляющий из себя программный модуль способный хранить, обрабатывать и выдавать графический анализ введенных данных. 
        <br>
        <strong>2.1.3.</strong> Администрация сайта Интернет-сервиса – уполномоченные сотрудники на управления Сайтом, действующие от имени ООО «Р-СИСТЕМС».
        <br>
        <strong>2.1.4.</strong> Пользователь – лицо, имеющее доступ к Сервису, посредством сети Интернет и использующее его.
        <br>
        <strong>2.1.5.</strong> Содержание сайта Интернет-сервиса (далее – Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте Интернет-сервиса.
      </p>
      <h6>3.	ПРЕДМЕТ СОГЛАШЕНИЯ</h6>
      <p class="fs--1">
        <strong>3.1.</strong>  Предметом настоящего Соглашения является предоставление Пользователю Интернет-сервиса доступа к содержащимся на Сайте функциональным возможностям.
        <br>
        <strong>3.1.1</strong>. Интернет-сервис предоставляет Пользователю следующие виды услуг (сервисов):
        <br>
        -	доступ к электронному контенту на платной основе, с внесения информации, ее хранения и обработки, просмотра контента;
        <br>
        -	доступ к средствам поиска и навигации Интернет-сервиса;
        <br>
        -	предоставление Пользователю возможности размещения сообщений, комментариев, рецензий Пользователей, выставления оценок контенту Интернет-сервиса;
        <br>
        - иные виды услуг (сервисов), реализуемые на страницах Интернет-сервиса.
        <br>
        <strong>3.1.2.</strong> Под действие настоящего Соглашения подпадают весь существующий на данный момент функционал (сервисы) Интернет-сервиса, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-сервиса.
        <br>
        <strong>3.2.</strong> Доступ к Интернет-сервису предоставляется на платной основе.
        <br>
        <strong>3.3.</strong> Настоящее Соглашение является публичной офертой. Получая доступ к Сайту - Пользователь считается присоединившимся к настоящему Соглашению. 
        <br>
        <strong>3.4.</strong> Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации
      </p>
      <h6>4.	ПРАВА И ОБЯЗАННОСТИ СТОРОН</h6>
      <p class="fs--1">
        <strong>4.1. Администрация сайта вправе:</strong>
        <br> 
        <strong>4.1.1.</strong> Изменять правила пользования Сервисом, а также изменять содержание данного Сервиса. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сервисе.
        <br>
        <strong>4.1.2.</strong> Ограничить доступ к Сервису в случае нарушения Пользователем условий настоящего Соглашения.
        <br>
        <strong>4.1.3</strong>. Изменять размер оплаты, взимаемый за предоставление доступа к использованию сайта Интернет-сервиса. Изменение стоимости не будет распространяться на Пользователей, имеющих оплаченную подписку на Сервис к моменту изменения размера оплаты, за исключением случаев, особо оговоренных Администрацией сайта интернет-сервиса.
        <br>
        <strong>4.2. Пользователь вправе:</strong>
        <br>
        <strong>4.2.1.</strong> Получить доступ к использованию Сервиса после соблюдения требований о регистрации и оплате одного из существующих тарифных планов.
        <br>
        <strong>4.2.2.</strong> Пользоваться всеми имеющимися в Сервисе услугами, а также приобретать любые Товары, предлагаемые на Сайте.
        <br>
        <strong>4.2.3.</strong> Задавать любые вопросы, относящиеся к услугам Интернет-сервиса по реквизитам, которые находятся в разделе Сайта «Контакты».
        <br>
        <strong>4.2.4.</strong> Пользоваться Сервисом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.
        <br>
        <strong>4.3. Пользователь Сайта обязуется:</strong>
        <br>
        <strong>4.3.1.</strong> Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сервиса.
        <br>
        <strong>4.3.2.</strong> Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сервиса.
        <br>
        <strong>4.3.3.</strong> Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сервиса.
        <br>
        <strong>4.3.4.</strong> Не распространять с использованием Сервиса любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах. 
        <br>
        <strong>4.3.5.</strong> Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.
        <br>
        <strong>4.3.6.</strong> Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.
        <br>
        <strong>4.3.7.</strong> Не использовать сайт Интернет-сервиса с целью:
        <br>
        <strong>4.3.7.1.</strong> загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти. 
        <br>
        <strong>4.3.7.2</strong>. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
        <br>
        <strong>4.3.7.3.</strong> нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
        <br>
        <strong>4.3.7.4</strong>. ущемления прав меньшинств. 
        <br>
        <strong>4.3.7.5.</strong> представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного Интернет-сервиса.
        <br>
        <strong>4.4. Пользователю запрещается:</strong>
        <br>
        <strong>4.4.1.</strong> Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта данного Интернет-сервиса.
        <br>
        <strong>4.4.2.</strong> Нарушать надлежащее функционирование Сервиса.
        <br>
        <strong>4.4.3.</strong> Любым способом обходить навигационную структуру Сервиса для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта.
        <br>
        <strong>4.4.4.</strong> Несанкционированный доступ к функциям Сервиса, любым другим системам или сетям, относящимся к данному Сервису, а также к любым услугам, предлагаемым на Сайте.
        <br>
        <strong>4.4.5.</strong> Нарушать систему безопасности или аутентификации Сервиса или в любой сети, относящейся к Сайту. 
        <br>
        <strong>4.4.6.</strong> Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сервиса.
        <br>
        <strong>4.4.7.</strong> Использовать Сервис и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности.
      </p>
      <h6>5.	ИСПОЛЬЗОВАНИЕ САЙТА ИНТЕРНЕТ-СЕРВИСА</h6>
      <p class="fs--1">
        <strong>5.1.</strong> Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта. 
        <br>
        <strong>5.2.</strong> Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.
        <br>
        <strong>5.3.</strong> Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.
        <br>
        <strong>5.4.</strong> Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи. 
        <br>
        <strong>5.5.</strong> Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном использовании его учётной записи или пароля или любом другом нарушении системы безопасности. 
        <br>
        <strong>5.6.</strong> Администрация сайта обладает правом в одностороннем порядке аннулировать учетную запись Пользователя, если она не использовалась более количество месяцев календарных месяцев подряд без уведомления Пользователя.
        <br>
        <strong>5.7.</strong> Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и оказанию услуг, предоставляемых на Сайте. 
        <br>
        <strong>5.8.</strong> Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.
        <br>
        <strong>5.9.</strong> Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по их реализации и (или) оказываемым услугам Интернет-сервисом.
      </p>
      <h6>6.	ОТВЕТСТВЕННОСТЬ</h6>
      <p class="fs--1"> 
        <strong>6.1.</strong> Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.
        <br>
        <strong>6.2.</strong> Администрация сайта не несет ответственности за:
        <br>
        <strong>6.2.1.</strong> Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.
        <br>
        <strong>6.2.2.</strong> Действия систем переводов, банков, платежных систем и за задержки, связанные с их работой.
        <br>
        <strong>6.2.3.</strong> Надлежащее функционирование Сервиса в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами. 
      </p>
      <h6>7.	НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h6>
      <p class="fs--1">
        <strong>7.1.</strong> Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сервиса информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сервиса либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей.
        <br>
        <strong>7.2.</strong> Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности название организации, Пользователей.
        <br>
        <strong>7.3.</strong> Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.
        <br>
        <strong>7.4.</strong> Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сервису, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сервисом, а также в случае прекращения действия Сервиса либо по причине технической неполадки или проблемы.
        <br>
        <strong>7.5.</strong> Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сервиса в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сервисом.
      </p>
      <h6>8.	РАЗРЕШЕНИЕ СПОРОВ</h6>
      <p class="fs--1">
        <strong>8.1.</strong> В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).
        <br>
        <strong>8.2.</strong> Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
        <br>
        <strong>8.3.</strong> При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.
        <br>
        <strong>8.4.</strong> Любой иск в отношении условий использования Сайта должен быть предъявлен в течение срок после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.
      </p>
      <h6>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h6>
      <p class="fs--1">
        <strong>9.1.</strong> Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения. 
        <br>
        <strong>9.2.</strong> Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.
      </p>
      <h6>Обновлено: 15 мая 2020 года</h6>

   
    </b-modal>
  </div>     
</template>

<script>
export default {
  data: () => ({
      showModal: false
  }),
  methods: {
    onShow() {

    },
    onHide() {

    }
  }
}
</script>

<style>

</style>